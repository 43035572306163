
import { checkIfOwned } from '../api'
const state = {
  checkedFlusses: {},
  selectedDevice: null,
  selectedContacts: []
}
const mutations = {
  CLEAR_OWNED_RESULTS (state) {
    state.checkedFlusses = {}
  },
  SET_OWNED_RESULT (state, { flussName, result }) {
    state.checkedFlusses[flussName] = result
    console.info(state)
  },
  SET_SELECTED_DEVICE (state, val) {
    state.selectedDevice = val
  },
  DESELECT_DEVICE (state) {
    state.selectedDevice = null
  },
  SET_SELECTED_CONTACTS (state, val) {
    const index = state.selectedContacts.indexOf(val)
    if (index >= 0) {
      state.selectedContacts.splice(index, 1)
    } else {
      state.selectedContacts.push(val)
    }
  },
  DESELECT_All_CONTACTS (state) {
    state.selectedContacts = []
  }
}
const actions = {
  async checkIfOwned ({ state, commit }, flussName) {
    console.log('checking for owner', flussName)
    if (state.checkedFlusses[flussName]) {
      return state.checkedFlusses[flussName]
    }
    const result = await checkIfOwned(flussName)
    commit('SET_OWNED_RESULT', { flussName, result })
    return result
  },
  selectDevice ({ commit }, val) {
    commit('SET_SELECTED_DEVICE', val)
  },
  deselectDevice ({ commit }) {
    commit('DESELECT_DEVICE')
  },
  selectContact ({ commit }, val) {
    commit('SET_SELECTED_CONTACTS', val)
  },
  deselectContact ({ commit }) {
    commit('DESELECT_ALL_CONTACTS')
  }

}
const getters = {
  selectedDevice: state => state.selectedDevice,
  selectedContacts: state => state.selectedContacts
}
export default {
  state,
  mutations,
  actions,
  getters
}
