import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss'
import { auth } from './config/firebase'
import { onAuthStateChanged } from 'firebase/auth'

import './style/btdm.scss'

createApp(App).use(store).use(router).mount('#app')

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch('setUser', user)
  }
  if (!user && router.currentRoute.value.path !== '/login') {
    router.push('/login')
  } else {
    router.push('/')
  }
})
