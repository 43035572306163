import { auth } from '../../config/firebase'
import router from '../../router'
// import { auth, db } from '../../config/firebase'
import { signOut } from 'firebase/auth'
// import { ref, child, get } from 'firebase/database'

import { getUser, isFlussUser, addContact, deleteContact, updateQuickAccess } from '../api'

const state = {
  user: null,
  userProfile: {}
}

const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_USER_PROFILE (state, userProfile) {
    state.userProfile = userProfile
  }
}

const actions = {
  logout ({ commit }) {
    signOut(auth).then(() => {
      commit('SET_USER', null)
      commit('SET_USER_PROFILE', {})
      router.push('/login')
    })
  },
  async getUser ({ commit }) {
    console.log('getting user')
    commit('SET_USER_PROFILE', {})
    const ret = await getUser()
    if (ret.data) {
      console.log('setting user profile user')
      await commit('SET_USER_PROFILE', ret.data)
      return ret.data
    }
  },
  async setUser ({ dispatch, commit }, user) {
    await commit('SET_USER', user)
    await dispatch('getUser')
  },
  async isFlussUser ({ _ }, mobile) {
    const ret = await isFlussUser(mobile)
    if (ret) {
      return ret
    }
  },
  async addContact ({ dispatch }, contact) {
    console.log('STORE ADDCONTACT', contact)
    const ret = await addContact(contact)
    console.log('RET addContact', ret)
    if (ret.data) {
      console.log('RET')
      await dispatch('getUser')
      return ret.data
    }
  },
  async deleteContact ({ dispatch }, contactId) {
    console.log('STORE deleteContact', contactId)
    const ret = await deleteContact(contactId)
    console.log('RET DELETE', ret)
    if (ret.data) {
      await dispatch('getUser')
      return ret.data
    }
  },
  async updateQuickAccess ({ dispatch }, details) {
    console.log('STORE updateQuickAccess', details)
    const ret = await updateQuickAccess(details)
    console.log('RET updateQuickAccess', ret)
    if (ret.data) {
      await dispatch('getUser')
      return ret.data
    }
  }
}

const getters = {
  user: state => {
    return state.user
  },
  userProfile: state => {
    return state.userProfile
  },
  flusses: state => {
    return state.userProfile && state.userProfile.flusse ? state.userProfile.flusse : {}
  },
  hasDevices: state => {
    return state.userProfile && state.userProfile.flusse && Object.keys(state.userProfile.flusse).length > 0
  },
  myDevices: state => {
    const arr = []
    if (state.userProfile && state.userProfile.flusse) {
      for (const [key, value] of Object.entries(state.userProfile.flusse)) {
        if (value.owner === 'Fluss admin') {
          arr.push({ id: key, ...value })
        } else if (value.userType === 'user') {
          arr.push({ id: key, ...value })
        }
      }
    }
    return arr
  },
  myContacts: state => {
    const arr = []
    if (state.userProfile && state.userProfile.contacts) {
      for (const [key, value] of Object.entries(state.userProfile.contacts)) {
        arr.push({ id: key, ...value })
      }
    }
    return arr
  },
  quickAccess: state => {
    if (state.userProfile && state.userProfile.quickAccess) {
      return state.userProfile.quickAccess
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
