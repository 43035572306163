import { functions } from '../config/firebase'
import { httpsCallable } from 'firebase/functions'

const getUser = async (userId) => {
  const getUser = httpsCallable(functions, 'apiV1UserGetUser')
  return await getUser(userId)
}
const updateAccess = (access) => {
  const updateAccess = httpsCallable(functions, 'apiV1UpdateAccess')
  updateAccess(access).then((result) => {
    console.log(result)
  })
}
const isFlussUser = (mobile) => {
  const isUser = httpsCallable(functions, 'apiV1ContactsIsUser')
  return isUser({ mobile: mobile }).then((result) => {
    return result
  })
}
const addContact = (contact) => {
  console.log('api addContact', contact)
  const addContact = httpsCallable(functions, 'apiV1ContactsAddContact')
  return addContact(contact).then((result) => {
    return result
  })
}
const deleteContact = (contact) => {
  const deleteContact = httpsCallable(functions, 'apiV1ContactsRemoveContact')
  return deleteContact(contact).then((result) => {
    return result
  })
}
const updateQuickAccess = (access) => {
  console.log('api updateQuickAccess', access)
  const updateQuickAccess = httpsCallable(functions, 'apiV1UserUpdateQuickAccess')
  return updateQuickAccess(access).then((result) => {
    return result
  })
}

const revokeAccess = (invitation) => {
  const revokeAccess = httpsCallable(functions, 'apiV1RevokeAccess')
  revokeAccess(invitation).then((result) => {
    console.log(result)
  })
}
const createShareLink = (invitation) => {
  const giveLink = httpsCallable(functions, 'apiV1GiveLink')
  return giveLink(invitation)
}
const sendInvite = (invitation) => {
  const giveInvite = httpsCallable(functions, 'apiV1GiveInvite')
  return giveInvite(invitation)
}
const checkIfOwned = (flussId) => {
  const requestOwner = httpsCallable(functions, 'apiV1RequestOwner')
  return requestOwner(flussId)
}
const claimAccess = (flussId) => {
  const claimFluss = httpsCallable(functions, 'apiV1ClaimAccess')
  return claimFluss(flussId)
}
const sendOTP = ({ mobile, country }) => {
  console.log('mobile', mobile, 'country', country)
  if (mobile) {
    const authSend = httpsCallable(functions, 'apiV1AuthSendOtp')
    return authSend({ mobile, country })
  }
}
const verifyOTP = ({ mobile, otp }) => {
  if (mobile && otp) {
    const authVerify = httpsCallable(functions, 'apiV1AuthVerifyOtp')
    return authVerify({ mobile: mobile, otp: otp })
  }
}
const signInWithToken = ({ token }) => {
  const authVerify = httpsCallable(functions, 'apiV1AuthVerifyToken')
  return authVerify({ idToken: token })
}
const updateUser = (updates) => {
  const updateUserProfile = httpsCallable(functions, 'apiV1UpdateUser')
  return updateUserProfile({ updates })
}
const deleteAccount = (updates) => {
  const deleteUserAccount = httpsCallable(functions, 'apiV1DeleteUser')
  return deleteUserAccount()
}
const logAccess = ({ flussId, timestamp }) => {
  const logUserAccess = httpsCallable(functions, 'apiV1LogAccess')
  return logUserAccess({ flussId, timestamp })
}

export {
  getUser,
  updateAccess,
  isFlussUser,
  revokeAccess,
  createShareLink,
  sendInvite,
  checkIfOwned,
  claimAccess,
  sendOTP,
  verifyOTP,
  updateUser,
  deleteAccount,
  logAccess,
  signInWithToken,
  addContact,
  deleteContact,
  updateQuickAccess
}
