<template>
  <router-view/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      scrolled: false
    }
  },
  async mounted () {
    await this.getAllUserInfo()
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'flusses',
      'myDevices',
      'user'
    ])
  },
  methods: {
    ...mapActions([
      'getUser'
    ]),
    async getAllUserInfo () {
      if (this.user) {
        await this.getUser()
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.25rem !important;
}
.topBar {
  height: 64px;
}
.sideMenu-width {
  width: 230px;
}
.pTop {
  padding-top: 64px;
}
.pTopMore {
  padding-top: 50px;
}
.pSide {
  padding-left: 0;
}
.pointer {
  cursor: pointer;
}
.btn-white {
  background-color: #fff !important;
  color: #1a78b4 !important;
  border-color: #fff !important;
}
.btn-white:hover {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}
.form-control {
  background-color: transparent !important;
}
.form-control:focus {
  border-color: var(--bs-primary) !important;
  outline: 0;
  box-shadow: none !important;
}
.input-group-text {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.rotateDown {
   -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.2s ease-in-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rotateUp {
   -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.2s ease-in-out;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.hover:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
@media (min-width: 992px) {
  .pSide {
    padding-left: 230px;
  }
}
</style>
